import React from 'react';
import {ResponsivePie} from '@nivo/pie';

const PieChart = ({ data, margin, clickHandler }) => {
    return (
      <div className="ResponsivePie w-64 h-64">
          <ResponsivePie
            data={data}
            margin={margin}
            activeOuterRadiusOffset={8}
            colors={{ datum: 'data.color' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            enableArcLinkLabels={false}
            arcLabel={e => `${e.label} (${e.data.percentage}%)`}
            arcLabelsSkipAngle={25}
            arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', '2.5' ] ] }}
            arcLabelsRadiusOffset={0.7}
            onClick={clickHandler}
          />
      </div>
    );
};

export default PieChart;
