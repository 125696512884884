import PropTypes from "prop-types";
import ViewRow from "./ViewRow";
import EditRow from "./EditRow";
import { TASK_CATEGORIES } from "../../../shared/constants";

const columns = [
  {
    label: "Task description",
    field: "description",
    placeholder: "Description",
    width: "",
    type: "string",
  },
  {
    label: "Time",
    field: "time",
    additionalTxt: "hour",
    placeholder: "# of hours",
    width: "",
    type: "number",
  },
  {
    label: "Category",
    field: "category",
    width: "w-3/8",
    placeholder: "Category",
    type: "select",
  },
  {
    label: "Notes",
    field: "note",
    placeholder: "Add any additional comments you'd like",
    type: "string",
  },
];

const Table = ({
  tasks = [],
  onSubmitTask,
  onTaskDelete,
  isAdd,
  setIsAdd,
  editRow,
  setEditRow,
}) => {
  const options = Object.values(TASK_CATEGORIES);
  return (
    <div>
      <table className="shadow my-2 w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col.field}
                className={`${col.width} px-6 py-3 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}
              >
                <div className="flex">
                  <p className="mr-2">{col.label}</p>
                </div>
              </th>
            ))}
            <th className="w-1/8 px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" />
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {tasks.map((task) =>
            editRow === task.virtualId ? (
              <EditRow
                key={task.virtualId}
                columns={columns}
                task={task}
                onSave={(values) => {
                  onSubmitTask(values);
                  setEditRow(null);
                }}
                onCancel={() => {
                  setEditRow(null);
                }}
                options={options}
              />
            ) : (
              <ViewRow
                key={task.virtualId}
                columns={columns}
                task={task}
                onEditClicked={() => setEditRow(task.virtualId)}
                onDeleteSubmit={() => onTaskDelete(task.virtualId)}
                options={options}
              />
            )
          )}
          {isAdd && (
            <EditRow
              key="new"
              columns={columns}
              task={{ description: "", category: "", note: "", time: "" }}
              onSave={(values) => {
                onSubmitTask(values);
                setIsAdd(false);
              }}
              onCancel={() => setIsAdd(false)}
              options={options}
            />
          )}
        </tbody>
      </table>
      <div className="text-right mt-4">
        <button
          disabled={isAdd || editRow}
          onClick={() => {
            setIsAdd(true);
          }}
          className="py-2 px-4 ml-6 border border-current rounded-md shadow-sm text-sm font-medium text-green-700 bg-white hover:text-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 disabled:text-gray-200"
        >
          Add another task +
        </button>
      </div>
    </div>
  );
};

Table.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default Table;
