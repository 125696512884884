import PropTypes from "prop-types";

const FormInput = ({
  label,
  name,
  values,
  placeholder,
  onChange,
  type,
  value,
}) => {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          id={name}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value ?? values[name]}
          onChange={onChange}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm"
        />
      </div>
    </div>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default FormInput;
