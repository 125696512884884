export const TASK_CATEGORIES = {
  customer_experience: {
    name: "Customer Experience",
    label: "Customer Experience",
    id: "customer_experience",
    color: "#6C5889",
  },
  marketing: {
    name: "Marketing",
    label: "Marketing",
    id: "marketing",
    color: "#6B94CA",
  },
  operations: {
    name: "Operations",
    label: "Operations",
    id: "operations",
    color: "#EB874B",
  },
  sales: {
    name: "Sales",
    label: "Sales",
    id: "sales",
    color: "#9ACD32",
  },
};

export const TASK_FREQUENCY = {
  day: "day",
  week: "week",
  month: "month",
};
