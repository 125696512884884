import { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../../shared/assets/icons/Close";
import CheckIcon from "../../../../shared/assets/icons/Check";
import CellEdit from "./CellEdit";

const EditRow = ({ columns, task, onSave, onCancel, options }) => {
  const [values, setValues] = useState(task);
  return (
    <tr className="bg-white group">
      {columns.map((col) => (
        <td
          className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900"
          key={col.field}
        >
          <div className="flex">
            <CellEdit
              task={values}
              column={col}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              options={options}
            />
          </div>
        </td>
      ))}

      <td className="px-6 py-4 text-center">
        <div className="flex items-center w-full justify-between">
          <button
            onClick={() => onSave(values)}
            className="px-2 text-orange hover:text-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
          >
            <CheckIcon className="w-6 h-6" />
          </button>
          <button
            onClick={onCancel}
            className="px-2 text-red-500 hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800"
          >
            <CloseIcon className="w-6 h-6" />
          </button>
        </div>
      </td>
    </tr>
  );
};

EditRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      label: PropTypes.string,
      additionalTxt: PropTypes.string,
    })
  ),
  task: PropTypes.shape({
    description: PropTypes.string,
    category: PropTypes.string,
    notes: PropTypes.string,
    time: PropTypes.string,
  }),
};

export default EditRow;
