import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Table from './Table';
import { TASK_CATEGORIES, TASK_FREQUENCY } from '../../shared/constants';
import PieChart from '../../shared/components/PieChart';
import { addNumbers, toFixedNumber } from '../../shared/utils/taskUtils';

const freqKeys = {
  day: { label: 'daily', quantifier: 250 },
  week: { label: 'weekly', quantifier: 50 },
  month: { label: 'monthly', quantifier: 12 },
};

const ViewReport = () => {
  const history = useHistory();
  const [member, setMember] = useState({});
  const [tasks, setTasks] = useState({ day: [], week: [], month: [] });
  const [chartData, setChartData] = useState({ day: [], week: [], month: [] });
  const [notes1, setNotes1] = useState('');
  const [notes2, setNotes2] = useState('');
  const [notes3, setNotes3] = useState('');
  const [totalPerWeek, setTotalPerWeek] = useState(0);
  const { userId } = useParams();

  const getData = () => {
    axios
      .get(`/tasks/${userId}`)
      .then(({ data }) => {
        setMember(data.user);
        setTasks(data.tasksByFrequency);
        setTotalPerWeek(
          toFixedNumber(
            (data.tasksByFrequency.day.totalPerYear +
              data.tasksByFrequency.week.totalPerYear +
              data.tasksByFrequency.month.totalPerYear) /
              50
          )
        );
        setNotes1(data.notes1);
        setNotes2(data.notes2);
        setNotes3(data.notes3);
        const charts = Object.keys(TASK_FREQUENCY).map((freq) => {
          const chart = Object.values(TASK_CATEGORIES).map((cat) => ({
            ...cat,
            value: 0,
          }));
          const totalHours = toFixedNumber(
            data.tasksByFrequency[freq].byCategory.reduce(
              (prev, curr) => prev + curr.hours,
              0
            ),
            1
          );
          data.tasksByFrequency[freq].byCategory.forEach((item) => {
            const chartLine = chart.find((c) => c.id === item.name);
            chartLine.value = addNumbers(chartLine.value, item.hours);
            chartLine.percentage = toFixedNumber(
              (100 / totalHours) * item.hours,
              2
            );
          });
          return chart;
        });
        setChartData({ day: charts[0], week: charts[1], month: charts[2] });
      })
      .catch((err) => {
        history.push('/');
      });
  };

  useEffect(() => {
    getData();
  }, [userId]);

  return (
    <div className='bg-gray-100'>
      <div className='max-w-7xl mx-auto md:p-0 bg-gray-100'>
        <div className='mt-4 border-b border-solid border-gray-200 w-96'>
          <p className='text-gray-900 font-medium'>{member.name}</p>
          <p className='text-sm text-gray-500 mb-4 capitalize'>
            {member.title || member.role}
          </p>
        </div>
        <div className='pt-10 w-full'>
          {Object.keys(TASK_FREQUENCY).map((freq) => (
            <div
              className='mb-10 flex items-center flex-wrap justify-between'
              key={freq}
            >
              <div className='lg:max-w-2xl xl:max-w-4xl w-full overflow-x-scroll lg:overflow-x-visible'>
                <h4 className='text-lg font-bold capitalize'>
                  {freqKeys[freq].label} Tasks
                </h4>
                <p className='my-4 text-gray-500'>
                  This information will be displayed publicly so be careful what
                  you share.
                </p>
                <Table
                  tasks={tasks[freq].tasks}
                  freq={freq}
                  userId={userId}
                  getData={getData}
                />
                <p className='font-bold'>
                  Total: {toFixedNumber(tasks[freq].total)}h (
                  {toFixedNumber(tasks[freq].totalPerYear)} hours per year)
                </p>
                {freq === 'day' && null}
                {freq === 'week' && (
                  <p className='font-bold'>
                    Hours Daily (5 day work week):{' '}
                    {toFixedNumber(tasks[freq].total / 5)}h
                  </p>
                )}
                {freq === 'month' && (
                  <p className='font-bold'>
                    Hours Daily (20 day work month):{' '}
                    {toFixedNumber(tasks[freq].total / 20)}h
                  </p>
                )}
              </div>
              <div className='w-64 h-64 text-xs'>
                {!!chartData[freq] && (
                  <PieChart
                    data={chartData[freq]}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  />
                )}
              </div>
            </div>
          ))}
          <div className='pb-10 w-full'>
            <p className='font-bold'>Total Hours per week: {totalPerWeek}h</p>
            <p className='font-bold'>
              Average Total Hours per day: {toFixedNumber(totalPerWeek / 5)}h
            </p>
          </div>
          <div className='flex items-start flex-wrap'>
            <div className='w-full md:w-1/3 md:pr-4'>
              <label className='pb-3 text-sm inline-block'>
                Greatest Inefficiency.......
              </label>
              <p className='w-full border-gray-400 border border-solid p-2 rounded-md text-gray-500 mb-2 min-h-sm'>
                {notes1 || '-'}
              </p>
            </div>
            <div className='w-full md:w-1/3 md:px-4'>
              <label className='pb-3 text-sm inline-block'>
                Largest pain point: ........
              </label>
              <p className='w-full border-gray-400 border border-solid p-2 rounded-md text-gray-500 mb-2 min-h-sm'>
                {notes2 || '-'}
              </p>
            </div>
            <div className='w-full md:w-1/3 md:pl-4'>
              <label className='pb-3 text-sm inline-block'>
                Biggest win......
              </label>
              <p className='w-full border-gray-400 border border-solid p-2 rounded-md text-gray-500 min-h-sm'>
                {notes3 || '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReport;
