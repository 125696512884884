import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import rightImg from "../../shared/assets/login.jpeg";
import AddCircleIcon from "../../shared/assets/icons/AddCircle";
import { teamSignUpAsync } from "../../shared/redux/reducers/auth";
import FormInput from "../../shared/components/FormInput";

const formSimpleInputs = [
  {
    label: "Your team's name",
    name: "teamName",
    placeholder: "Dunder Mifflin",
    type: "text",
  },
  {
    label: "Your name",
    name: "adminName",
    placeholder: "Michael Scott",
    type: "text",
  },

  {
    label: "Your email address",
    name: "adminEmail",
    type: "email",
    placeholder: "michael@dundermifflin.com",
  },
  {
    label: "Create a password",
    name: "adminPassword",
    type: "password",
    placeholder: "Create a password",
  },
];

const TeamSignUp = () => {
  const [values, setValues] = useState({
    teamName: "",
    adminName: "",
    adminEmail: "",
    adminPassword: "",
  });
  const [members, setMembers] = useState([]);
  const [memberEmail, setMemberEmail] = useState("");
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let m = [...members];
    if (memberEmail) {
      m.push(memberEmail);
    }
    dispatch(teamSignUpAsync({ ...values, members: m })).then((action) => {
      if (action.type === "auth/team-signup/fulfilled") {
        history.push("/");
      }
    });
  };

  return (
    <div className="min-h-screen-w-header bg-white flex w-screen -ml-4 items-center justify-center py-4">
      <div className="flex flex-col-reverse  max-w-4xl  md:flex-row  w-full mx-7 md:mx-0  shadow-3xl">
        <div className="flex-1 flex flex-col justify-center py-6 md:px-8 px-4">
          <div className="mx-auto w-full max-w-md xl:w-97">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                Let's put the GAP Analyzer to work for your team!
              </h2>
              <p className="mt-2 text-sm text-gray-600">Get started below...</p>
            </div>

            <div className="mt-6">
              <form className="space-y-4">
                {formSimpleInputs.map(({ name, label, placeholder, type }) => (
                  <FormInput
                    key={name}
                    onChange={onChange}
                    label={label}
                    name={name}
                    type={type}
                    values={values}
                    placeholder={placeholder}
                  />
                ))}
                <FormInput
                  onChange={(e) => setMemberEmail(e.target.value)}
                  label="Team member's email address"
                  name="memberEmail"
                  placeholder="dwight@dundermifflin.com"
                  value={memberEmail}
                />

                <div className="text-sm">
                  <button
                    type="button"
                    onClick={() => {
                      if (memberEmail) {
                        setMembers([...members, memberEmail]);
                        setMemberEmail("");
                      }
                    }}
                    className="font-medium flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                  >
                    <AddCircleIcon className="mr-3 text-orange hover:text-orange-700" />{" "}
                    Add teammates
                  </button>
                  {members.map((member) => (
                    <div className="flex align-center mt-2" key={member}>
                      <button
                        type="button"
                        onClick={() =>
                          setMembers(members.filter((m) => m !== member))
                        }
                        className="text-red-600 mr-2 font-extrabold text-lg leading-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                      >
                        &times;
                      </button>
                      <p>{member}</p>
                    </div>
                  ))}
                </div>

                {error && (
                  <p className="text-sm leading-5 font-medium text-red-800 mb-1">
                    {error}
                  </p>
                )}
                <div>
                  <button
                    type="submit"
                    onClick={onSubmit}
                    // disabled={
                    //   Object.values(values).some((v) => !v) ||
                    //   members.length < 1
                    // }
                    className="w-full flex flex-col text-center  justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange disabled:bg-gray-300"
                  >
                    <span className=" w-full text-xl font-bold">
                      Mind the GAP
                    </span>
                    <span className="w-full text-xd font-light block">
                      Click to Submit
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className=" block flex-1">
          <div className="h-full w-full relative">
            <iframe
              width="100%"
              height="100%"
              src="https://player.vimeo.com/video/580774380"
              title="GAP Analyzer"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSignUp;
