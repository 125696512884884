import { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../shared/assets/logoHighRes.png";
import MenuIcon from "../../shared/assets/icons/Menu";
import CloseIcon from "../../shared/assets/icons/Close";
import { logoutAsync } from "../../shared/redux/reducers/auth";

const Header = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logoutAsync()).then(() => history.push("/login"));
  };
  return (
    <header className="absolute top-0 left-0 z-20 shadow w-full px-4 bg-white">
      <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
                <div className="flex justify-start">
                  <Link to="/" className="flex">
                    <img
                      className="h-10 w-auto"
                      src={logo}
                      alt="GAP Analysis"
                    />
                  </Link>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                {currentUser.role === "admin" && (
                  <nav className="hidden md:flex space-x-10">
                    <div className="flex items-center ml-10 text-gray-600 text-center">
                      <Link to="/" className="mr-4">
                        <b>My report</b>
                      </Link>
                      <Link to={`/team/${currentUser.TeamId}`}>
                        <b>Team reports</b>
                      </Link>
                    </div>
                  </nav>
                )}
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  {currentUser.id ? (
                    <div className="flex items-center justify-center">
                      <a href="http://www.gapanalyzer.com/gap-tutorials">
                        <span className="font-light">Are you stuck? </span>
                        <b className="text-gray-600">Get Help</b>
                      </a>
                      <p className="ml-8 text-gray-600 text-center">
                        <b>{currentUser.name}</b> |{" "}
                        <span className="font-light capitalize">
                          {currentUser.title || currentUser.role}
                        </span>
                      </p>
                      <button
                        type="button"
                        onClick={handleLogout}
                        className="ml-8 inline-flex items-center justify-center p-1 lg:px-4 lg:py-2 border border-transparent rounded-md text-base font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                      >
                        Log out
                      </button>
                    </div>
                  ) : (
                    <a href="mailto:hq@thecollabteam.com">
                      <span className="font-light">Are you Stuck? </span>
                      <b className="text-gray-600">Get Help</b>
                    </a>
                  )}
                </div>
                {!currentUser.id && (
                  <a
                    href="https://www.thecollabteam.com/collab-scheduler"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hidden ml-8 md:flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange hover:bg-orange-700"
                  >
                    Schedule Call with Aaron
                  </a>
                )}
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-0 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <Link to="/" className="flex">
                        <img
                          className="h-8 w-auto sm:h-10"
                          src={logo}
                          alt="GAP Analysis"
                        />
                      </Link>
                      {currentUser.id ? (
                        <p className="text-gray-600 text-center">
                          <b>{currentUser.name}</b> |{" "}
                          <span className="font-light capitalize">
                            {currentUser.title || currentUser.role}
                          </span>
                        </p>
                      ) : null}
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange">
                          <span className="sr-only">Close menu</span>
                          <CloseIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    {currentUser.role === "admin" && (
                      <nav className="space-x-10">
                        <div className="flex flex-col items-center text-gray-600 text-center">
                          <Link to="/" className="my-4">
                            <b>My report</b>
                          </Link>
                          <Link
                            to={`/team/${currentUser.TeamId}`}
                            className="my-4"
                          >
                            <b>Team reports</b>
                          </Link>
                        </div>
                      </nav>
                    )}
                    <div className="flex items-center justify-center">
                      <a href="mailto:hq@thecollabteam.com">
                        <span className="font-light">Are you Stuck? </span>
                        <b className="text-gray-600">Get Help</b>
                      </a>
                    </div>
                    {currentUser.id ? (
                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          onClick={handleLogout}
                          className="inline-flex items-center justify-center p-1 lg:px-4 lg:py-2 border border-transparent rounded-md text-base font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                        >
                          Log out
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <a
                          href="https://www.thecollabteam.com/collab-scheduler"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange hover:bg-orange-700"
                        >
                          Schedule Call with Aaron
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  );
};

export default Header;
