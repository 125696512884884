import { Route, Switch } from "react-router-dom";
import SignIn from './auth/SignIn';
import Header from './layout/Header';
import TeamSignUp from './auth/TeamSignUp';
import AuthRoute from './auth/AuthRoute';
import SignUp from './auth/SignUp';
import Dashboard from './dashboard';
import ViewTeamReports from './dashboard/ViewTeamReports';
import ViewReport from './dashboard/ViewReport';

function App() {
  return (
    <div className="relative px-4 pt-header min-h-screen bg-gray-100">
      <Header />
      <Switch>
        <Route path="/login">
          <SignIn />
        </Route>
        <Route path="/team-signup">
          <TeamSignUp />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <AuthRoute path="/report/:userId">
          <ViewReport />
        </AuthRoute>
        <AuthRoute path="/team/:teamId">
          <ViewTeamReports />
        </AuthRoute>
        <AuthRoute path="/">
          <Dashboard />
        </AuthRoute>
      </Switch>
    </div>
  );
}

export default App;
