import {useSelector} from 'react-redux';
import CreateReport from './CreateReport';
import ViewTeams from './ViewTeams';

const Dashboard = () => {
  const {currentUser} = useSelector(state => state.auth);
  switch (currentUser.role) {
    case "sa":
      return <ViewTeams />
    // case "admin":
    //   return <ViewTeamReports />
    default:
      return <CreateReport />
  }
};

export default Dashboard;
