import PropTypes from "prop-types";
import Select from "../../../../../shared/components/Select";

const CellEdit = ({ task, column, options, onChange, errors }) => {
  return column.type === "select" ? (
    <Select
      value={options.find(({ id }) => id === task[column.field])}
      options={options}
      onChange={(value) => onChange({ target: { name: column.field, value } })}
      placeholder={column.placeholder}
      error={errors[column.field]}
    />
  ) : column.type === "number" ? (
    <input
      className={`w-full border-0 border-b border-solid p-0 border-t-0 border-l-0 border-r-0 text-inherit no-spin-buttons leading-inherit focus:border-orange focus:outline-none focus:ring-0 ${
        errors[column.field] ? "border-red-500" : "border-gray-300"
      }`}
      name={column.field}
      value={task[column.field]}
      placeholder={column.placeholder}
      onChange={onChange}
      type="number"
    />
  ) : (
    <textarea
      className={`w-full border-0 border-b border-solid p-0 border-t-0 border-l-0 border-r-0 text-inherit no-spin-buttons leading-inherit focus:border-orange focus:outline-none focus:ring-0 ${
        errors[column.field] ? "border-red-500" : "border-gray-300"
      }`}
      name={column.field}
      value={task[column.field]}
      placeholder={column.placeholder}
      onChange={onChange}
    />
  );
};

CellEdit.propTypes = {
  task: PropTypes.object,
  column: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    additionalTxt: PropTypes.string,
    type: PropTypes.oneOf(["string", "number", "select"]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default CellEdit;
