import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loginAsync = createAsyncThunk("auth/signin", async payload => {
  const response = await axios.post(`/auth/login`, payload);
  return response.data;
});

export const checkAuthStatusAsync = createAsyncThunk("auth/status", async () => {
  const response = await axios.get(`/auth/status`);
  return response.data;
});

export const teamSignUpAsync = createAsyncThunk("auth/team-signup", async payload => {
  try {
    const response = await axios.post('/auth/team-signup', payload);
    return response.data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
});

export const finishSignUpAsync = createAsyncThunk("auth/finish-signup", async payload => {
  const response = await axios.post('/user/by-hash', payload);
  return response.data;
});

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  const response = await axios.get(`/auth/logout`);
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: true,
    error: null,
    isAuthenticated: false,
    currentUser: {},
  },
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
  },
  extraReducers: {
    [loginAsync.pending]: state => {
      state.loading = true;
    },
    [loginAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload?.isAuthenticated;
      if (!action.payload.id) {
        state.error = "Wrong username or password";
      } else {
        state.error = null;
        state.currentUser = action.payload;
      }
    },
    [loginAsync.rejected]: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = "Invalid credentials";
    },
    [teamSignUpAsync.pending]: state => {
      state.loading = true;
    },
    [teamSignUpAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentUser = action.payload;
    },
    [teamSignUpAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message.startsWith('Users') ? action.error?.message : "Something went wrong...";
    },
    [finishSignUpAsync.pending]: state => {
      state.loading = true;
    },
    [finishSignUpAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentUser = action.payload;
    },
    [finishSignUpAsync.rejected]: (state) => {
      state.loading = false;
      state.error = "Something went wrong...";
    },
    [checkAuthStatusAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload?.isAuthenticated;
      state.currentUser = action.payload?.currentUser || {};
    },
    [checkAuthStatusAsync.rejected]: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.currentUser = {};
    },
    [logoutAsync.fulfilled]: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.currentUser = {};
    },
  }
});
export const { clearErrors } = authSlice.actions;
export default authSlice.reducer;
