import React from "react";
import PropTypes from "prop-types";

const CellView = ({ task, column, options }) => {
  return (
    <p className="border-b border-transparent text-gray-600 text-left group-hover:text-gray-900 transition ease-in-out duration-150">
      {column.type === "select"
        ? options.find(({ id }) => id === task[column.field])?.name
        : task[column.field]}
      {column.additionalTxt
        ? ` ${column.additionalTxt}${task[column.field] > 1 ? "s" : ""}`
        : ""}
    </p>
  );
};

CellView.propTypes = {
  task: PropTypes.object,
  column: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    additionalTxt: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default CellView;
