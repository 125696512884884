import PropTypes from "prop-types";
import PencilIcon from "../../../../shared/assets/icons/Pencil";
import TrashIcon from "../../../../shared/assets/icons/Trash";
import CellView from "./CellView";

const ViewRow = ({ columns, task, onEditClicked, onDeleteSubmit, options }) => {
  return (
    <tr className="bg-white group">
      {columns.map((col) => (
        <td
          className="px-6 py-4 text-sm leading-5 text-gray-900 max-w-xs"
          key={col.field}
        >
          <CellView task={task} column={col} options={options} />
        </td>
      ))}

      <td className="px-6 py-4 text-center">
        <div className="flex items-center w-full justify-between">
          <button
            onClick={onEditClicked}
            className="px-2 text-blue-500 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
          >
            <PencilIcon className="w-6 h-6" />
          </button>
          <button
            onClick={onDeleteSubmit}
            className="px-2 text-red-600 hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800"
          >
            <TrashIcon className="w-6 h-6" />
          </button>
        </div>
      </td>
    </tr>
  );
};

ViewRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      label: PropTypes.string,
      additionalTxt: PropTypes.string,
    })
  ),
  task: PropTypes.shape({
    description: PropTypes.string,
    category: PropTypes.string,
    notes: PropTypes.string,
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onEditClicked: PropTypes.func,
  onDeleteSubmit: PropTypes.func,
};

export default ViewRow;
