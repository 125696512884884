import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, Route, Redirect} from "react-router-dom";
import {checkAuthStatusAsync} from '../../shared/redux/reducers/auth';

const AuthRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser, loading } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(checkAuthStatusAsync());
  }, [dispatch]);

  return !loading && (
    <Route {...rest}>
      {currentUser.id ? children : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location }
          }}
        />
      )}
    </Route>
  )
};

export default AuthRoute;
