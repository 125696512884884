import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const CategoriesModal = ({ isOpen, category, team, closeModal }) => {
  const [teamByCategory, setTeamByCategory] = useState([]);

  useEffect(() => {
    if (team && category) {
      const newTeamByCategory = team.users.map((user) => {
        return {
          id: user.id,
          name: user.name,
          role: user.role,
          title: user.title,
          hours: user.categories.filter((c) => c.name === category.id)[0].hours,
        };
      });
      setTeamByCategory(newTeamByCategory);
    }
  }, [category, team]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="absolute top-1 right-1">
          <button type="button" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-white transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-100 rounded-lg px-8 pt-10 pb-8 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="max-w-7xl mx-auto md:p-0">
                <h1 className="font-bold text-3xl text-center text-orange">
                  {category?.label}
                </h1>
                <div className="mt-4 flex items-start justify-between flex-wrap">
                  <ul className="divide-y divide-gray-200 w-full">
                    {teamByCategory.map((u) =>
                      u.hours > 0 ? (
                        <li key={u.id} className="py-4">
                          <Link
                            to={`/report/${u.id}`}
                            className="flex justify-between items-center"
                          >
                            <div>
                              <p className="text-sm font-medium text-gray-900">
                                {u.name || "Unconfirmed User"}
                              </p>
                              <p className="text-sm text-gray-500 capitalize">
                                {u.title || u.role}
                              </p>
                            </div>
                            <b className="">{u.hours}h</b>
                          </Link>
                        </li>
                      ) : false
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CategoriesModal;
