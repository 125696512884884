import { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import Steps from './Steps';
import Table from './Table';
import { TASK_CATEGORIES, TASK_FREQUENCY } from '../../shared/constants';
import Modal from '../../shared/components/Modal';
import {
  addNumbers,
  toFixedNumber,
  generateUniqueId,
} from '../../shared/utils/taskUtils';
import PieChart from '../../shared/components/PieChart';

const initialSteps = [
  {
    id: '01',
    name: 'Daily Tasks',
    href: '#daily',
    status: 'current',
    key: 'day',
  },
  {
    id: '02',
    name: 'Weekly Tasks',
    href: '#weekly',
    status: 'upcoming',
    key: 'week',
  },
  {
    id: '03',
    name: 'Monthly Tasks',
    href: '#monthly',
    status: 'upcoming',
    key: 'month',
  },
  { id: '04', name: 'Notes', href: '#notes', status: 'upcoming', key: 'notes' },
];

const exampleDailyTasks = [
  {
    description:
      'EXAMPLE: Daily check up and communication: Paypal, Upkeep, Whatsapp, Email',
    time: 1.5,
    category: 'operations',
    note: 'Once a day login to check on PayPal',
    virtualId: generateUniqueId(),
  },
  {
    description: 'EXAMPLE: Updates: My daily update and HR/Regional Updates',
    time: 0.7,
    category: 'operations',
    note: '',
    virtualId: generateUniqueId(),
  },
  {
    description:
      'EXAMPLE: Approval of specials, communication on new products, advisement on product discounts',
    time: 3,
    category: 'marketing',
    note: 'Coordinates with Touchpoint quite a bit, also the vendors',
    virtualId: generateUniqueId(),
  },
];

const exampleWeeklyTasks = [
  {
    description: 'EXAMPLE: Meetings: Friday 3pm, Tuesday 10:30am',
    time: 3,
    category: 'operations',
    note: 'Various meeting with different teams',
    virtualId: generateUniqueId(),
  },
  {
    description:
      'EXAMPLE: Customer refunds, giftcard help, POS update, add/remove POS items, miscellaneous requests',
    time: 5,
    category: 'customer_experience',
    note: 'I need to create tutorials for these',
    virtualId: generateUniqueId(),
  },
  {
    description: 'EXAMPLE: IT: Miscellaneous IT requests and fixes',
    time: 3,
    category: 'operations',
    note: 'Really would make more sense for us to pass this on to Tech Team',
    virtualId: generateUniqueId(),
  },
];

const exampleMonthlyTasks = [
  {
    description:
      'EXAMPLE: Payroll: bi-weekly reporting and organizing paystubs/checks for employees',
    time: 17,
    category: 'operations',
    note: '',
    virtualId: generateUniqueId(),
  },
  {
    description: 'EXAMPLE: Amazon: Monthly invoice report for bookkeeper',
    time: 1,
    category: 'operations',
    note: '',
    virtualId: generateUniqueId(),
  },
  {
    description:
      'EXAMPLE: Vendor Relations: communication, pricing, cost checks, contracts for shops',
    time: 7,
    category: 'operations',
    note: 'Possibly Collab Team help?',
    virtualId: generateUniqueId(),
  },
];

const CreateReport = () => {
  const location = useLocation();
  const history = useHistory();
  const [steps, setSteps] = useState(initialSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [tasks, setTasks] = useState({
    day: exampleDailyTasks,
    week: exampleWeeklyTasks,
    month: exampleMonthlyTasks,
  });
  const [chartData, setChartData] = useState({ day: [], week: [], month: [] });
  const [notes1, setNotes1] = useState('');
  const [notes2, setNotes2] = useState('');
  const [notes3, setNotes3] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [editRow, setEditRow] = useState(null);

  useEffect(() => {
    axios.get('/tasks').then((serverData) => {
      if (
        serverData.data.tasksByFrequency.day.tasks.length +
          serverData.data.tasksByFrequency.week.tasks.length +
          serverData.data.tasksByFrequency.month.tasks.length >
        0
      ) {
        setTasks({
          day: serverData.data.tasksByFrequency.day.tasks,
          week: serverData.data.tasksByFrequency.week.tasks,
          month: serverData.data.tasksByFrequency.month.tasks,
        });
      }
      setNotes1(serverData.data.notes1);
      setNotes2(serverData.data.notes2);
      setNotes3(serverData.data.notes3);
    });
  }, []);

  useEffect(() => {
    const currentStep = initialSteps.findIndex(
      (step) => step.href === location.hash
    );
    const currentIndex = currentStep >= 0 ? currentStep : 0;
    setCurrentStep(currentIndex);
    setSteps((s) =>
      s.map((step, index) => ({
        ...step,
        status:
          index === currentIndex
            ? 'current'
            : index > currentIndex
            ? 'upcoming'
            : 'complete',
      }))
    );
  }, [location.hash]);

  useEffect(() => {
    const charts = Object.keys(TASK_FREQUENCY).map((freq) => {
      const emptyChart = Object.values(TASK_CATEGORIES).map((cat) => ({
        ...cat,
        value: 0,
      }));
      const byCategory = Object.keys(TASK_CATEGORIES).map((name) => ({
        name,
        hours: 0,
      }));
      const totalHours = toFixedNumber(
        tasks[freq].reduce((prev, curr) => prev + Number(curr.time), 0),
        1
      );
      tasks[freq].forEach((task) => {
        const recordCategory = byCategory.find(
          ({ name }) => name === task.category
        );
        recordCategory.hours = addNumbers(recordCategory.hours, task.time);
      });
      byCategory.forEach((item) => {
        const chartLine = emptyChart.find((c) => c.id === item.name);
        chartLine.value = addNumbers(chartLine.value, item.hours);
        chartLine.percentage = toFixedNumber(
          (100 / totalHours) * chartLine.value,
          2
        );
      });
      return emptyChart;
    });
    setChartData({ day: charts[0], week: charts[1], month: charts[2] });
  }, [tasks]);

  const handlePrev = useCallback(() => {
    history.push({ hash: steps[currentStep - 1].href });
  }, [steps, history, currentStep]);

  const handleNext = useCallback(() => {
    history.push({ hash: steps[currentStep + 1].href });
  }, [steps, history, currentStep]);

  const onSubmitTask = (task) => {
    const newTasks = [...tasks[steps[currentStep].key]];
    if (!task.virtualId) {
      task.virtualId = generateUniqueId();
      newTasks.push(task);
    } else {
      const editedTaskIndex = newTasks.findIndex(
        (t) => t.virtualId === task.virtualId
      );
      if (editedTaskIndex >= 0) {
        newTasks[editedTaskIndex] = task;
      }
    }

    setTasks({ ...tasks, [steps[currentStep].key]: newTasks });
  };

  const onTaskDelete = (virtualId) => {
    const currentTasks = [...tasks[steps[currentStep].key]];
    const newTasks = currentTasks.filter((val) => val.virtualId !== virtualId);
    setTasks({ ...tasks, [steps[currentStep].key]: newTasks });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (currentStep !== steps.length - 1) {
      handleNext();
      return;
    }
    const normalizedTasks = [];
    Object.entries(tasks).forEach(([frequency, tasks]) =>
      tasks.forEach((task) =>
        normalizedTasks.push({ ...task, frequency, time: Number(task.time) })
      )
    );
    await axios.post('/task/bulk-create', {
      tasks: normalizedTasks,
      notes1,
      notes2,
      notes3,
    });
    setModalOpen(true);
  };

  return (
    <div className='max-w-7xl mx-auto md:p-0 bg-gray-100 pb-10'>
      <div className='mt-4 flex items-center md:justify-between flex-wrap justify-center'>
        <Steps steps={steps} className='lg:max-w-2xl xl:max-w-5xl w-full' />
      </div>
      <div className='flex items-center flex-wrap justify-between'>
        <div className='mt-4 lg:max-w-3xl xl:max-w-5xl w-full overflow-x-scroll lg:overflow-x-visible'>
          {steps[currentStep].key === 'notes' ? (
            <div>
              <p className='text-sm mb-5 text-gray-700 max-w-2xl'>
                <b>Nobody knows your job like you.</b>
              </p>
              <p className='text-sm mb-5 text-gray-700 max-w-2xl'>
                Please <b>give us some feedback</b> below on the following. Your
                answers can be about your job/position in particular or about
                the company as a whole. Constructive criticism only please. With
                any problem, please give us your suggested solution.
              </p>
              <p className='text-sm mb-5 text-gray-700 max-w-2xl'>
                <b>Thank you!</b>
              </p>
              <div className='flex items-center flex-wrap'>
                <div className='w-full md:w-1/3 md:pr-4'>
                  <label className='pb-3 text-sm inline-block'>
                    Greatest Inefficiency.......
                  </label>
                  <textarea
                    className='w-full text-sm resize-none focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange'
                    rows={4}
                    value={notes1}
                    onChange={(e) => setNotes1(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-1/3 md:px-4'>
                  <label className='pb-3 text-sm inline-block'>
                    Largest pain point: ........
                  </label>
                  <textarea
                    className='w-full text-sm resize-none focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange'
                    rows={4}
                    value={notes2}
                    onChange={(e) => setNotes2(e.target.value)}
                  />
                </div>
                <div className='w-full md:w-1/3 md:pl-4'>
                  <label className='pb-3 text-sm inline-block'>
                    Biggest win......
                  </label>
                  <textarea
                    className='w-full text-sm resize-none focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange'
                    rows={4}
                    value={notes3}
                    onChange={(e) => setNotes3(e.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className='mt-10 mb-2 text-2xl'>
                What do you work on <b>every {steps[currentStep].key}</b>?
              </p>
              <p className='text-sm mb-5 text-gray-700 max-w-2xl'>
                See examples below of <b>task descriptions</b>, <b>time</b>, and{' '}
                <b>categories</b>. By sharing some details about{' '}
                <b>your {steps[currentStep].key}</b> we'll be able to see how we
                can optimize our team to be more efficient and productive.
              </p>
              <p className='text-sm mb-5 text-gray-700 max-w-2xl'>
                Click the <b>Pencil icon</b> to edit and the{' '}
                <b>Trash can icon</b> to delete.
              </p>
              <Table
                tasks={tasks[steps[currentStep].key]}
                onSubmitTask={onSubmitTask}
                onTaskDelete={onTaskDelete}
                isAdd={isAdd}
                editRow={editRow}
                setEditRow={setEditRow}
                setIsAdd={setIsAdd}
              />
            </div>
          )}
          <hr className='border-b-gray-300 mt-6' />
          <div className='text-right w-full my-6'>
            {currentStep !== 0 && (
              <button
                disabled={isAdd || editRow}
                onClick={handlePrev}
                className='mr-4 py-2 px-4 border border-current rounded-md shadow-sm text-sm font-medium text-orange bg-white hover:text-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange disabled:text-gray-200'
              >
                Previous
              </button>
            )}
            <button
              disabled={isAdd || editRow}
              onClick={onSubmit}
              className='py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange disabled:bg-gray-300'
            >
              Save & {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>
        <div className='w-64 h-64 text-xs hidden lg:block'>
          {!!chartData[steps[currentStep].key] && (
            <PieChart
              data={chartData[steps[currentStep].key]}
              margin={{ top: 20, right: 40, bottom: 20, left: 40 }}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={() => {
          setModalOpen(false);
          history.push('/#daily');
        }}
      />
    </div>
  );
};

export default CreateReport;
