import React from 'react';

const AddCircle = props => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 17.8486C2 9.01208 9.16344 1.84863 18 1.84863C26.8366 1.84863 34 9.01208 34 17.8486C34 26.6852 26.8366 33.8486 18 33.8486C9.16344 33.8486 2 26.6852 2 17.8486Z" fill="currentColor"/>
      <path d="M18 11.8486V17.8486M18 17.8486V23.8486M18 17.8486H24M18 17.8486L12 17.8486" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default AddCircle;
