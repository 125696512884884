import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CellView from './CellView';
import EditRow from '../EditRow';
import axios from 'axios';
import PencilIcon from '../../../../shared/assets/icons/Pencil';
import TrashIcon from '../../../../shared/assets/icons/Trash';

const ViewRow = ({ columns, task, options, getData }) => {
  const { currentUser } = useSelector((state) => state.auth);
  const [rowEdit, setRowEdit] = useState(false);

  // allow sa to edit values
  const onSave = (values) => {
    setRowEdit(false);
    axios.post(`/task/`, values).then((res) => {
      getData();
    });
  };

  // delete task
  const rowDelete = () => {
    const confirm = window.confirm(
      `Are you sure you want to delete this task - ${task.description}`
    );
    if (confirm) {
      axios.delete(`/task/${task.id}`).then((res) => {
        getData();
      });
    }
  };

  return (
    <>
      {!rowEdit ? (
        <tr className='bg-white group'>
          {columns.map((col) => (
            <td
              className='px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 max-w-xs'
              key={col.field}
            >
              <CellView task={task} column={col} options={options} />
            </td>
          ))}
          {/* Edit Column */}
          {currentUser.role === 'sa' ? (
            <td className='flex my-6'>
              <button
                className='px-2 text-blue-500 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700'
                onClick={() => setRowEdit(!rowEdit)}
              >
                <PencilIcon className='w-6 h-6' />
              </button>
              <button
                className='px-2 text-red-500 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700'
                onClick={rowDelete}
              >
                <TrashIcon className='w-6 h-6' />
              </button>
            </td>
          ) : null}
        </tr>
      ) : (
        <EditRow
          columns={columns}
          task={task}
          options={options}
          onSave={onSave}
          onCancel={() => setRowEdit(!rowEdit)}
        />
      )}
    </>
  );
};

ViewRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      label: PropTypes.string,
      additionalTxt: PropTypes.string,
    })
  ),
  task: PropTypes.shape({
    description: PropTypes.string,
    category: PropTypes.string,
    note: PropTypes.string,
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onEditClicked: PropTypes.func,
  onDeleteSubmit: PropTypes.func,
};

export default ViewRow;
