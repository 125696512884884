import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import CheckIcon from "../../assets/icons/Check";
import ChevronDown from "../../assets/icons/ChevronDown";

const Select = ({ value, onChange, options, placeholder, error }) => {
  return (
    <Listbox value={value?.id} onChange={onChange}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className={`bg-white relative w-full pb-2 font-bold border-b shadow-sm pr-10 text-left cursor-default focus:outline-none focus:border-orange sm:text-sm ${error ? "border-red-500" : "border-gray-300"}`}>
            <span
              className={`block truncate ${
                !value?.name ? "text-gray-500" : ""
              }`}
            >
              {value?.name || placeholder}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDown
                className="h-5 w-5 pb-2 text-gray-600"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    `${
                      active ? "text-white bg-orange" : "text-gray-900"
                    } cursor-default select-none relative py-2 pl-3 pr-9`
                  }
                  value={option.id}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-semibold" : "font-normal"
                        } block truncate`}
                      >
                        {option.name}
                      </span>

                      {selected ? (
                        <span
                          className={`${
                            active ? "text-white" : "text-orange"
                          } absolute inset-y-0 right-0 flex items-center pr-4`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default Select;
