import PropTypes from 'prop-types';
import ViewRow from './ViewRow';
import { TASK_CATEGORIES } from '../../../shared/constants';
import { useSelector } from 'react-redux';

const columns = [
  { label: 'Task description', field: 'description', type: 'string' },
  { label: 'Time', field: 'time', additionalTxt: 'hour', type: 'number' },
  { label: 'Category', field: 'category', type: 'select' },
  { label: 'Notes', field: 'note', type: 'string' },
];

const Table = ({ tasks = [], userId = 0, getData }) => {
  const options = Object.values(TASK_CATEGORIES);
  const { currentUser } = useSelector((state) => state.auth);
  const stl2 = `px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`;
  return (
    <div>
      {/* removed class sm:w-1/3 from table */}
      <table className='shadow my-2 w-full divide-y divide-gray-200'>
        <thead>
          <tr>
            {columns.map((col) => {
              let size = 'sm:w-1/3';
              if (col.field === 'time' || col.field === 'category')
                size = 'sm:w-1/6';
              const stl = `${size} ${stl2}`;
              return (
                <th key={col.field + userId} className={stl}>
                  <div className='flex'>
                    <p className='mr-2'>{col.label}</p>
                  </div>
                </th>
              );
            })}
            {currentUser.role === 'sa' ? (
              <th className={`sm:w-1/6 ${stl2}`}>
                <div className='flex justify-end'>
                  <p className='mr-2'>Edit</p>
                </div>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {tasks.map((task, i) => (
            <ViewRow
              key={task.description + task.time}
              columns={columns}
              task={task}
              options={options}
              getData={getData}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  userId: PropTypes.string,
};

export default Table;
