import {Link} from 'react-router-dom';
import {useEffect, useState} from "react";
import axios from "axios";
import MailIcon from '../../shared/assets/icons/Mail';
import ChevronRightIcon from '../../shared/assets/icons/ChevronRight';

const ViewTeams = () => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    axios.get('/teams').then(resp => {
      setTeams(resp.data);
    })
  }, []);

  return (
    <div className="min-h-screen-w-header bg-gray-100 flex w-full items-center justify-center py-4">
      <div className="max-w-6xl w-full mx-7 md:mx-0 2xl:pb-28">
        <h1>Teams List</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {teams.map((team) => (
              <li key={team.id}>
                <Link to={`/team/${team.id}`} className="block hover:bg-gray-50">
                  <div className="px-4 py-4 flex items-center sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex text-sm">
                          <p className="font-medium text-orange truncate">{team.name}</p>
                        </div>
                        <div className="mt-2 flex">
                          <div className="flex items-center text-sm text-gray-500">
                            <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>
                              {team.Users[0].email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 flex-shrink-0">
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ViewTeams;
