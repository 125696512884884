import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, loginAsync } from "../../shared/redux/reducers/auth";
import FormInput from "../../shared/components/FormInput";
import img from "../../shared/assets/login.jpeg";

const SignIn = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onChange = (event) => {
    dispatch(clearErrors());

    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(loginAsync(values)).then((action) => {
      if ("auth/signin/fulfilled" === action.type) {
        history.push(location.state?.from || "/");
      }
    });
  };

  return (
    <div className="h-screen-w-header bg-white flex w-screen -ml-4 items-center justify-center">
      <div className=" xl:max-w-6xl max-w-sm w-full mx-7 md:mx-0 flex shadow-3xl 2xl:pb-28">
        <div className="flex-1 flex flex-col justify-center py-12 2xl:pt-28 2xl:pb-0 px-4 sm:px-6 xl:flex-none xl:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-md xl:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                The <span className="text-orange">GAP</span> Analyzer
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                Sign in to your account
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6">
                  <FormInput
                    onChange={onChange}
                    name="email"
                    type="email"
                    values={values}
                    label="Email address"
                  />
                  <FormInput
                    onChange={onChange}
                    name="password"
                    type="password"
                    values={values}
                    label="Password"
                  />
                  {/*<div className="text-sm">*/}
                  {/*  <Link to="/forgot-password" className="font-medium text-orange hover:text-orange-700">*/}
                  {/*    Forgot your password?*/}
                  {/*  </Link>*/}
                  {/*</div>*/}

                  {error && (
                    <p className="text-sm leading-5 font-medium text-red-800 mb-1">
                      {error}
                    </p>
                  )}
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg- bg-orange hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                      onClick={onSubmit}
                    >
                      See the Progress
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex flex-col justify-between w-0 flex-1 lg:pb-12 2xl:pb-0">
          <div className="h-64 w-full relative">
            <img
              src={img}
              alt="team work"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="flex justify-end flex-col">
            <h3 className="mt-6 text-3xl font-extrabold text-gray-900 mr-4">
              Because Efficiency is the Name of the Game
            </h3>
            <p className="mt-2 text-sm text-gray-600 mr-4">
              "The GAP Analyzer is the easiest and clearest way to identify
              inefficiency gaps on your team." Great Minds Everywhere
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
