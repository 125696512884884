import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import rightImg from "../../shared/assets/teamSignUp.png";
import { finishSignUpAsync } from "../../shared/redux/reducers/auth";
import FormInput from "../../shared/components/FormInput";

const formInputs = [
  { label: "Your name", name: "name" },
  { label: "Your email", name: "email" },
  { label: "Your title", name: "title" },
  { label: "Password", name: "password", type: "password" },
];

const TeamSignUp = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    title: "",
  });
  const [invite, setInvite] = useState({});
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (location.search) {
      axios
        .get(`/user/by-hash`, {
          params: { inviteHash: location.search.slice(12) },
        })
        .then((resp) => {
          console.log(resp.data);
          setInvite(resp.data);
          setValues((v) => ({ ...v, email: resp.data.email }));
        });
    }
  }, [location]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      finishSignUpAsync({ ...values, inviteHash: location.search.slice(12) })
    ).then((action) => {
      if (action.type === "auth/finish-signup/fulfilled") {
        history.push("/");
      }
    });
  };

  return (
    <div className="h-screen bg-white flex w-screen -ml-4 items-center justify-center">
      <div className="flex flex-col-reverse  max-w-4xl  md:flex-row  w-full mx-7 md:mx-0  shadow-3xl">
        <div className=" flex flex-col justify-center py-6 md:px-8 px-4">
          <div className="mx-auto w-full  max-w-md md:w-97">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                <span className="text-orange">Create an Account</span> and let's
                put the GAP Analyzer to work for {invite.Team?.name}
              </h2>
            </div>

            <div className="mt-6">
              <form className="space-y-4">
                {formInputs.map(({ name, label, type }) => (
                  <FormInput
                    key={name}
                    onChange={onChange}
                    label={label}
                    name={name}
                    values={values}
                    type={type}
                  />
                ))}

                {error && (
                  <p className="text-sm leading-5 font-medium text-red-800 mb-1">
                    {error}
                  </p>
                )}

                <div>
                  <button
                    type="submit"
                    onClick={onSubmit}
                    disabled={!values.email || !values.name || !values.password}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange disabled:bg-gray-300"
                  >
                    Get Started
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className=" w-full  md:mr-2">
          <div className="h-full w-full relative">
            <iframe
              width="100%"
              height="100%"
              src="https://player.vimeo.com/video/580503709"
              title="GAP Analyzer"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSignUp;
